// This is a manifest file that'll be compiled into application.js, which will include all the files
// listed below.
//
// Any JavaScript/Coffee file within this directory, lib/assets/javascripts, or any plugin's
// vendor/assets/javascripts directory can be referenced here using a relative path.
//
// It's not advisable to add code directly here, but if you do, it'll appear at the bottom of the
// compiled file. JavaScript code in this file should be added after the last require_* statement.
//
// Read Sprockets README (https://github.com/rails/sprockets#sprockets-directives) for details
// about supported directives.
//
// ---ここから 20190925コメントアウトして frontend/packs/application.jsに移行
import Rails from "@rails/ujs"
window.Rails = Rails
Rails.start()

import "core-js/stable"
import "regenerator-runtime/runtime"
global.$ = require("jquery")
// global.VueMaterial = require("expose-loader?vue-material!vue-material")
import "chartkick"
import "../packs/common"
import "../stylesheets/main"
// ---ここまで
